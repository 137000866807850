import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, IconButton, Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const UserInfoCard = ({ userInfo }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState(userInfo);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSave = () => {
    // Here you can add the logic to save the updated user data
    setIsEditing(false);
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">User Information</Typography>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Box>
        {isEditing ? (
          <>
            <TextField
              label="Name"
              name="name"
              value={userData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              name="email"
              value={userData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Password"
              name="password"
              value={userData.password}
              onChange={handleChange}
              type="password"
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1">Name: {userData.name}</Typography>
            <Typography variant="body1">Email: {userData.email}</Typography>
            <Typography variant="body1">Password: {userData.password}</Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default UserInfoCard;
