import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getEbayTokens } from '../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const EbayCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEbayToken = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get('code');
      const encodedState = params.get('state').split("auth_type")
      const state = encodedState[0];

      if (code) {
        try {
          await getEbayTokens({ code, state });
          toast.success('eBay account connected successfully');
          navigate('/dashboard');
        } catch (error) {
          toast.error('Failed to connect eBay account');
        }
      }
    };

    fetchEbayToken();
  }, [location.search]);

  return (<div>Connecting to eBay...</div>);
};

export default EbayCallback;