import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import heroAreaImage from '../assets/images/hero-area.svg';
import introMobileImage from '../assets/images/intro-mobile.png';

const HeroContainer = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100vh', // Make it full viewport height
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${heroAreaImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const HeroContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '1200px',
  zIndex: 2,
});

const HeroText = styled('div')({
  maxWidth: '60%',
  zIndex: 2,
});

const HeroImage = styled('img')({
  height: 'auto',
});

const HeroSection = () => (
  <HeroContainer>
    <Container maxWidth="lg">
      <HeroContent>
        <HeroText>
          <Typography component="h1" variant="h2" color="textPrimary" gutterBottom>
            Welcome to StackrHub
          </Typography>
          <Typography variant="h5" color="textSecondary" paragraph>
            The best tool for managing your e-commerce inventory and orders.
          </Typography>
          <Box mt={2}>
            <Button variant="contained" color="primary" style={{ marginRight: '16px' }}>
              Get Started
            </Button>
            <Button variant="outlined" color="primary">
              Learn More
            </Button>
          </Box>
        </HeroText>
        <HeroImage src={introMobileImage} alt="Mobile Intro" />
      </HeroContent>
    </Container>
  </HeroContainer>
);

export default HeroSection;
