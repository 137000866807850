import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import { getDidYouKnowCardData } from '../../../api';
import { toast } from 'react-toastify';

const DidYouKnowModal = ({ open, handleCloseDidYouKnowModal }) => {
  // Default generic values for the modal
  const defaultData = {
    id: 0,
    image: 'https://via.placeholder.com/500x200?text=Did+You+Know', // Placeholder image
    title: 'Did You Know?',
    description: 'Here is something you should know...',
    articleId: null, // No article by default
  };

  const [didYouKnowData, setDidYouKnowData] = useState(defaultData);
  const [loading, setLoading] = useState(false);

  // Fetch data only when modal is opened
  useEffect(() => {
    if (open) {
      fetchDidYouKnowData();
    }
  }, [open]);

  const fetchDidYouKnowData = async () => {
    try {
      setLoading(true);
      const response = await getDidYouKnowCardData();
      setDidYouKnowData(response);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to load data. Showing default information.');
      setLoading(false);
      // Keep the default values if the API call fails
    }
  };

  return (
    <Dialog open={open} handleCloseDidYouKnowModal={handleCloseDidYouKnowModal} fullWidth maxWidth="sm">
      <DialogTitle>
        {didYouKnowData.title}
        <Typography variant="caption" style={{ position: 'absolute', right: 8, top: 8 }}>
          ID: {didYouKnowData.id}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box component="img" src={didYouKnowData.image} alt="Did You Know" style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
        <Typography variant="h6" align="center" style={{ marginTop: '16px' }}>
          {didYouKnowData.title}
        </Typography>
        <Typography variant="body1" align="center" style={{ marginTop: '16px' }}>
          {didYouKnowData.description}
        </Typography>
      </DialogContent>
      <DialogActions>
        {didYouKnowData.articleId && (
          <Button
            color="primary"
            onClick={() => window.open(`/help-center?topicId=${didYouKnowData.articleId}`, '_blank')}
          >
            Learn More
          </Button>
        )}
        <Button onClick={handleCloseDidYouKnowModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DidYouKnowModal;
