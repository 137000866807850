import React from 'react';
import ProductFilters from './ProductFilters';
import OrderFilters from './OrderFilters';
import PurchaseOrderFilters from './PurchaseOrderFilters';

const Filters = ({ viewMode, onApplyFilters }) => {
  return (
    <div>
      {viewMode === 'Products' && <ProductFilters onApplyFilters={onApplyFilters} />}
      {viewMode === 'Orders' && <OrderFilters onApplyFilters={onApplyFilters} />}
      {viewMode === 'PurchaseOrders' && <PurchaseOrderFilters onApplyFilters={onApplyFilters} />}
    </div>
  );
};

export default Filters;