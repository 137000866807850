import React, { useState, useEffect } from 'react';
import {
  Table, Tooltip, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, IconButton, Box, Menu, MenuItem, TablePagination, Typography, Link, Button, Select, FormControl, InputLabel
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import placeholderImage from '../../assets/products/placeholder.jpg';
import { fetchProducts, deleteProduct, bulkImportProducts} from '../../api';
import { toast } from 'react-toastify';
import EditProductModal from './modals/EditProductModal';
import WarehousesModal from './modals/WarehousesModal';
import ProductGroupsModal from './modals/ProductGroupsModal';

const ProductList = ({ filters }) => {
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(filters.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(filters.size || 10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openWarehousesModal, setOpenWarehousesModal] = useState(false);
  const [openProductGroupsModal, setOpenProductGroupsModal] = useState(false);
  const [selectedAction, setSelectedAction] = useState('Manage Warehouses'); // Default action

  useEffect(() => {
    const fetchAndSetProducts = async () => {
      try {
        const response = await fetchProducts(filters.filters, page, rowsPerPage, filters.sortField, filters.sortOrder);
        setProducts(response.content);
        setTotalCount(response.totalElements);
      } catch (error) {
        console.error('Error fetching products:', error);
        setError('Failed to fetch products. Please try again later.');
      }
    };
    fetchAndSetProducts();
  }, [filters, page, rowsPerPage]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await bulkImportProducts(formData);
        toast.success('File uploaded successfully');
      } catch (error) {
        console.error('Error importing products:', error);
        setError('Failed to fetch products. Please try again later.');
      }
    }
  };

  const handleMenuClick = (event, productId) => {
    setAnchorEl(event.currentTarget);
    setSelectedProductId(productId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenWarehousesModal = () => {
    setOpenWarehousesModal(true);
  };

  const handleCloseWarehousesModal = () => {
    setOpenWarehousesModal(false);
  };

  const handleEdit = () => {
    const productToEdit = products.find(product => product.id === selectedProductId);
    setSelectedProduct(productToEdit);
    setShowEditModal(true);
    handleMenuClose();
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedProduct(null);
  };

  const handleDelete = async () => {
    try {
      await deleteProduct(selectedProductId);
      toast.success('Product deleted successfully');
      setProducts(products.filter((product) => product.id !== selectedProductId));
      handleMenuClose();
    } catch (error) {
      toast.error('Failed to delete product');
      handleMenuClose();
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = products.map((product) => product.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (productId) => {
    setSelected((prevSelected) => {
      if (prevSelected.indexOf(productId) !== -1) {
        return prevSelected.filter(id => id !== productId);
      } else {
        return [...prevSelected, productId];
      }
    });
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleActionChange = (event) => {
    setSelectedAction(event.target.value);
  };

  const handleGoClick = () => {
    switch (selectedAction) {
      case 'Manage Warehouses':
        setOpenWarehousesModal(true);
        break;
      case 'Product Groups':
        setOpenProductGroupsModal(true);
        break;
      case 'Bulk Import Products':
        document.getElementById('bulk-import-file-input').click();
        break;
      // Other cases...
      default:
        alert('This feature is not yet available');
    }
  };

  if (error) {
    return (
      <Box>
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Products
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <FormControl variant="outlined" style={{ minWidth: 200 }}>
            <InputLabel>Select Action</InputLabel>
            <Select
              value={selectedAction}
              onChange={handleActionChange}
              label="Select Action"
            >
              <MenuItem value="Manage Warehouses">Manage Warehouses</MenuItem>
              <MenuItem value="Bulk Import Products">Bulk Import Products</MenuItem>
              <MenuItem value="Product Groups">Product Groups</MenuItem>
              <MenuItem value="Create New Listing">Create New Listing</MenuItem>
              <MenuItem value="Update Price on Market">Update Price on Market</MenuItem>
              <MenuItem value="Update Qty on Market">Update Qty on Market</MenuItem>
              <MenuItem value="Scheduled Jobs">Scheduled Jobs</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" onClick={handleGoClick}>
            Go
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < products.length}
                  checked={products.length > 0 && selected.length === products.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Selling QTY</TableCell>
              <TableCell>Reserved QTY</TableCell>
              <TableCell>Default Price</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
              const isItemSelected = isSelected(product.id);
              const defaultImage = product.images && product.images.length > 0
                ? `data:image/png;base64,${product.images[0].imageData}` // Format Base64 with proper prefix
                : placeholderImage;
              return (
                <TableRow
                  key={product.id}
                  selected={isItemSelected}
                  onClick={() => handleRowClick(product.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{product.id}</TableCell>
                  <TableCell>
                    <img src={defaultImage} alt={product.name} style={{ width: "50px", height: "50px" }} />
                  </TableCell>
                  <TableCell>{product.sku}</TableCell>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <React.Fragment>
                          {product.warehouses.map((warehouse, index) => (
                            <Typography key={index} variant="body2">
                              {`Warehouse: ${warehouse.warehouseId}, Qty: ${warehouse.qty}`}
                            </Typography>
                          ))}
                        </React.Fragment>
                      }
                      arrow
                    >
                      <span>{product.sellableQuantity}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{product.reservedQuantity}</TableCell>
                  <TableCell>{product.price}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, product.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedProductId === product.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleEdit}>Edit</MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

        {/* File Input */}
        <input
          type="file"
          id="bulk-import-file-input"
          accept=".csv, .xls, .xlsx"
          style={{ display: 'none' }} // Hidden input
          onChange={handleFileChange} // Handler for file selection
        />

      <EditProductModal
        open={showEditModal}
        handleClose={handleCloseEditModal}
        productData={selectedProduct}
      />
      <ProductGroupsModal
        open={openProductGroupsModal}
        handleClose={() => setOpenProductGroupsModal(false)}
      />
      <WarehousesModal
        open={openWarehousesModal}
        handleClose={handleCloseWarehousesModal}
      />
    </Box>
  );
};

export default ProductList;
