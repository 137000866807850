import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { FiDollarSign, FiStar, FiGift } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';

const CardContainer = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  textAlign: 'center',
  transition: 'transform 0.3s, box-shadow 0.3s',
  height: '100%', // Ensure all cards are the same height
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[4],
  },
}));

const IconCircle = styled(Box)(({ theme }) => ({
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '16px',
}));

const tiers = [
  {
    title: 'Basic',
    price: '99.99',
    icon: FiDollarSign,
    description: ['500 SKUs', '4 integrations', '1000 monthly orders', 'Bundle and variation support'],
  },
  {
    title: 'Pro',
    price: '299.99',
    icon: FiStar,
    description: ['3000 SKUs', '8 integrations', '4000 monthly orders', 'Bundle and variation support'],
  },
  {
    title: 'Premium',
    price: '499.99',
    icon: FiGift,
    description: ['Unlimited SKUs', 'Unlimited integrations', 'Unlimited monthly orders', 'Advanced analytics'],
  },
];

const PricingSection = () => {
  return (
    <Container maxWidth="md" component="section" style={{ padding: '80px 0' }}>
      <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
        Pricing
      </Typography>
      <Grid container spacing={5} alignItems="stretch">
        {tiers.map((tier, index) => {
          const IconComponent = tier.icon;

          return (
            <Grid item key={tier.title} xs={12} sm={4}>
              <CardContainer>
                <IconCircle>
                  <IconComponent size={30} color="#fff" />
                </IconCircle>
                <CardContent>
                  <Typography component="h3" variant="h5" color="textPrimary">
                    {tier.title}
                  </Typography>
                  <Typography component="h4" variant="h6" color="textSecondary">
                    ${tier.price} / month
                  </Typography>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <Box mt="auto"> {/* Ensures button stays at the bottom */}
                  <Button variant="contained" color="primary" component={RouterLink} to="/register">
                    Choose
                  </Button>
                </Box>
              </CardContainer>
            </Grid>
          );
        })}
      </Grid>
      <Box mt={4}>
        <Typography variant="body1" color="textSecondary" align="center">
          Sign up now and get a 7-day free trial. You can cancel anytime before the trial ends.
        </Typography>
      </Box>
    </Container>
  );
};

export default PricingSection;
