import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Switch,
  Link,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getIntegrationsByCompanyId } from '../../api';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import MarketTemplatesModal from './modals/MarketTemplatesModal';
import OtherIntegrationWizardModal from './modals/OtherIntegrationWizardModal';

const IntegrationsList = ({ filters }) => {
  const theme = useTheme();
  const [integrations, setIntegrations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const [openTemplateModal, setOpenTemplateModal] = useState(false);
  const [openFTPModal, setOpenFTPModal] = useState(false);

  useEffect(() => {
    const fetchAndSetIntegrations = async () => {
      try {
        const response = await getIntegrationsByCompanyId(filters);
        setIntegrations(response);
      } catch (error) {
//        toast.error('Failed to fetch integrations. Please try again later.');
      }
    };
    fetchAndSetIntegrations();
  }, [filters]);

  const handleMenuClick = (event, integrationId) => {
    setAnchorEl(event.currentTarget);
    setSelectedIntegrationId(integrationId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleToggle = (integrationId, field) => {
    toast.info(`Toggle ${field} functionality is under development.`);
  };

  const handleEdit = () => {
    toast.info('Edit functionality is under development.');
    handleMenuClose();
  };

  const handleDelete = () => {
    toast.info('Delete functionality is under development.');
    handleMenuClose();
  };

  const openFTPIntegrationModal = () => {
    setOpenFTPModal(true);
  };

  const renderTableHeader = (title, onAddClick, showTemplates = false) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: theme.palette.primary.main, color: 'white', padding: '8px 16px' }}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {showTemplates && (
          <Link href="#" onClick={() => setOpenTemplateModal(true)} sx={{ color: 'white', marginRight: '8px' }}>
            Templates
          </Link>
        )}
        <IconButton onClick={onAddClick} sx={{ color: 'white' }}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );

  const renderOtherTable = () => (
    <Box sx={{ marginBottom: '24px' }}>
      {renderTableHeader('Other', openFTPIntegrationModal)}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Custom Mapping</TableCell>
              <TableCell>Schedule</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations
              .filter(integration => integration.type === 'other')
              .map(integration => (
                <TableRow key={integration.id}>
                  <TableCell>{integration.id}</TableCell>
                  <TableCell>{integration.nickname}</TableCell>
                  <TableCell>{integration.type}</TableCell>
                  <TableCell>{integration.customMapping ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{integration.schedule}</TableCell>
                  <TableCell>{integration.notes}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, integration.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedIntegrationId === integration.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleEdit}>
                        <EditIcon fontSize="small" /> Edit
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <DeleteIcon fontSize="small" /> Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderMarketTable = (title, filterType, onAddClick) => (
    <Box sx={{ marginBottom: '24px' }}>
      {renderTableHeader(title, onAddClick, true)}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Inventory</TableCell>
              <TableCell>Orders</TableCell>
              <TableCell>FBA Shipped By</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations
              .filter(integration => integration.type === filterType)
              .map(integration => (
                <TableRow key={integration.id}>
                  <TableCell>{integration.id}</TableCell>
                  <TableCell>{integration.nickname}</TableCell>
                  <TableCell>{integration.type}</TableCell>
                  <TableCell>{integration.createdDate}</TableCell>
                  <TableCell>{integration.notes}</TableCell>
                  <TableCell>
                    <Switch
                      checked={integration.inventory}
                      onChange={() => handleToggle(integration.id, 'inventory')}
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={integration.orders}
                      onChange={() => handleToggle(integration.id, 'orders')}
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      checked={integration.fbaShippedBy}
                      onChange={() => handleToggle(integration.id, 'fbaShippedBy')}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, integration.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedIntegrationId === integration.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleEdit}>
                        <EditIcon fontSize="small" /> Edit
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <DeleteIcon fontSize="small" /> Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  const renderStandardTable = (title, filterType, onAddClick) => (
    <Box sx={{ marginBottom: '24px' }}>
      {renderTableHeader(title, onAddClick)}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nickname</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrations
              .filter(integration => integration.type === filterType)
              .map(integration => (
                <TableRow key={integration.id}>
                  <TableCell>{integration.id}</TableCell>
                  <TableCell>{integration.nickname}</TableCell>
                  <TableCell>{integration.type}</TableCell>
                  <TableCell>{integration.createdDate}</TableCell>
                  <TableCell>{integration.notes}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, integration.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedIntegrationId === integration.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleEdit}>
                        <EditIcon fontSize="small" /> Edit
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>
                        <DeleteIcon fontSize="small" /> Delete
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Box>
      {renderMarketTable('Markets', 'market', () => toast.info('Add Market Integration'))}
      {renderStandardTable('Shipping', 'shipping', () => toast.info('Add Shipping Integration'))}
      {renderStandardTable('Accounting', 'accounting', () => toast.info('Add Accounting Integration'))}
      {renderOtherTable()} {/* Render the Other table with FTP integration */}

      <MarketTemplatesModal open={openTemplateModal} handleClose={() => setOpenTemplateModal(false)} />

      {/* FTP Modal */}
      {openFTPModal && (
        <OtherIntegrationWizardModal
          open={openFTPModal}
          onClose={() => setOpenFTPModal(false)}
        />
      )}
    </Box>
  );
};

export default IntegrationsList;
