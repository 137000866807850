import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, IconButton,
  Table, Typography, TableBody, TableCell, TableHead, TableRow, Autocomplete, FormControlLabel, Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { searchProductsTop3, searchVendorsTop3, createPurchaseOrder } from '../../../api';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

const CreatePurchaseOrderModal = ({ open, onClose, onSave }) => {
  const [name, setName] = useState('');
  const [externalOrderId, setExternalOrderId] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('UNPAID');
  const [shippingStatus, setShippingStatus] = useState('UNSHIPPED');
  const [receivingStatus, setReceivingStatus] = useState('UNRECEIVED');
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorSearchResults, setVendorSearchResults] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const companyId = parseInt(localStorage.getItem('companyId'), 10);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (open) {
      const totalPOCount = parseInt(localStorage.getItem('totalPOCount'), 10) || 0;
      setName(`SH_${companyId}PO${totalPOCount + 1}`);
    }
  }, [open, companyId]);

  const handleAddProduct = () => {
    if (selectedProduct) {
      setProductList([...productList, {
        id: selectedProduct.id,
        sku: selectedProduct.sku,
        quantityOrdered: 0, // Use quantityOrdered instead of qty
        price: 0.0,
        total: 0.0
      }]);
      setSelectedProduct(null);
    } else {
      toast.error('Please select a product.');
    }
  };

  const handleProductSearch = async (event) => {
    const query = event.target.value;
    if (query.length >= 3) {
      try {
        const results = await searchProductsTop3(query);
        setSearchResults(results);
      } catch (error) {
        console.error('Error searching products:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleVendorSearch = debounce(async (event) => {
    const query = event.target.value;
    if (query.length >= 3) {
      try {
        const results = await searchVendorsTop3(query);
        setVendorSearchResults(results);
      } catch (error) {
        console.error('Error searching vendors:', error);
      }
    } else {
      setVendorSearchResults([]);
    }
  }, 300);

  const handleSave = async () => {
    if (isSaving) return; // Prevent multiple saves

    setIsSaving(true);

    const purchaseOrderData = {
      name,
      externalOrderId: externalOrderId.trim() === '' ? null : externalOrderId,
      paymentStatus,
      shippingStatus,
      receivingStatus,
      vendorId: selectedVendor?.id,
      companyId,
      productDetails: productList.map(product => ({
        productId: product.id,
        sku: product.sku,
        quantityOrdered: product.quantityOrdered, // Map quantityOrdered correctly
        price: product.price
      })),
    };

    try {
      await createPurchaseOrder(purchaseOrderData);
      onSave(purchaseOrderData);
      toast.success('Purchase Order created successfully');
      onClose();
    } catch (error) {
      console.error('Error creating purchase order:', error);
      toast.error('Failed to create Purchase Order');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteProduct = (index) => {
    setProductList(productList.filter((_, i) => i !== index));
  };

  const handleEditProduct = (index, key, value) => {
    const updatedProducts = productList.map((product, i) => {
      if (i === index) {
        if (key === 'quantityOrdered') {
          return {
            ...product,
            quantityOrdered: value,
            total: value * product.price
          };
        } else if (key === 'price') {
          return {
            ...product,
            price: value,
            total: product.quantityOrdered * value
          };
        }
      }
      return product;
    });
    setProductList(updatedProducts);
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
  };

  const handleFieldBlur = () => {
    setEditingIndex(null);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Create Purchase Order
        <IconButton onClick={onClose} style={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'row' }}>
        <Box flex={1} marginRight={2}>
          <Autocomplete
            options={searchResults}
            getOptionLabel={(option) => `${option.id} - ${option.sku} - ${option.name}`}
            value={selectedProduct}
            onInputChange={handleProductSearch}
            onChange={(event, newValue) => setSelectedProduct(newValue)}
            renderInput={(params) => <TextField {...params} label="Search Product" margin="normal" />}
          />
          <Button onClick={handleAddProduct} variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
            Add Product
          </Button>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Qty Ordered</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productList.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.id}</TableCell>
                  <TableCell>{product.sku}</TableCell>
                  <TableCell>
                    {editingIndex === index ? (
                      <TextField
                        type="number"
                        value={product.quantityOrdered}
                        onChange={(e) => handleEditProduct(index, 'quantityOrdered', Number(e.target.value))}
                        onBlur={handleFieldBlur}
                      />
                    ) : (
                      <Typography>{product.quantityOrdered}</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {editingIndex === index ? (
                      <TextField
                        type="number"
                        value={product.price}
                        onChange={(e) => handleEditProduct(index, 'price', Number(e.target.value))}
                        onBlur={handleFieldBlur}
                      />
                    ) : (
                      <Typography>{product.price.toFixed(2)}</Typography>
                    )}
                  </TableCell>
                  <TableCell>{(product.quantityOrdered * product.price).toFixed(2)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditClick(index)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteProduct(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box flex={1}>
          <TextField
            label="PO Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="External Order ID"
            fullWidth
            margin="normal"
            value={externalOrderId}
            onChange={(e) => setExternalOrderId(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={paymentStatus === 'PAID'} onChange={(e) => setPaymentStatus(e.target.checked ? 'PAID' : 'UNPAID')} />}
            label="Mark as Paid"
          />
          <FormControlLabel
            control={<Checkbox checked={shippingStatus === 'SHIPPED'} onChange={(e) => setShippingStatus(e.target.checked ? 'SHIPPED' : 'UNSHIPPED')} />}
            label="Mark as Shipped"
          />
          <FormControlLabel
            control={<Checkbox checked={receivingStatus === 'RECEIVED'} onChange={(e) => setReceivingStatus(e.target.checked ? 'RECEIVED' : 'UNRECEIVED')} />}
            label="Mark as Received"
          />
          <Autocomplete
            options={vendorSearchResults}
            getOptionLabel={(option) => `${option.id} - ${option.name}`}
            value={selectedVendor}
            onInputChange={handleVendorSearch}
            onChange={(event, newValue) => setSelectedVendor(newValue)}
            renderInput={(params) => <TextField {...params} label="Search Vendor" margin="normal" />}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save Purchase Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePurchaseOrderModal;
