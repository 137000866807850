import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const AboutSection = () => (
  <Container maxWidth="md" id="about" component="section" style={{ padding: '80px 0' }}>
    <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
      About Us
    </Typography>
    <Typography variant="h6" align="center" color="textSecondary" paragraph>
      At StackrHub, we are dedicated to revolutionizing the e-commerce landscape by providing top-notch tools for inventory and order management. Our mission is to empower businesses of all sizes to streamline their operations, boost efficiency, and drive growth.
    </Typography>
    <Typography variant="body1" align="center" color="textSecondary" paragraph>
      Founded on the principles of innovation and customer-centricity, StackrHub offers a comprehensive suite of solutions designed to meet the unique challenges of modern e-commerce. Our platform integrates seamlessly with major marketplaces like Amazon, eBay, Etsy, Shopify, and more, ensuring that your business stays connected and competitive.
    </Typography>
    <Typography variant="body1" align="center" color="textSecondary" paragraph>
      We pride ourselves on our commitment to quality, reliability, and continuous improvement. Our team of experts works tirelessly to deliver cutting-edge features and exceptional support, helping our customers navigate the complexities of online retail with confidence.
    </Typography>
    <Typography variant="body1" align="center" color="textSecondary" paragraph>
      By choosing StackrHub, you gain a partner dedicated to your success. We provide real-time insights, robust reporting, and intuitive tools that simplify your workflows and enhance your decision-making processes. Our goal is to free up your time, so you can focus on what you do best: growing your business.
    </Typography>
  </Container>
);

export default AboutSection;
