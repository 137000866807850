import React, { useState } from 'react';
import Header from '../shared/LoggedInHeader';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Container, Paper, Typography, Box, Grid, Card, CardContent, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';

const salesData = [
  { name: 'Jan', sales: 4000, amt: 2400 },
  { name: 'Feb', sales: 3000, amt: 2210 },
  { name: 'Mar', sales: 2000, amt: 2290 },
  { name: 'Apr', sales: 2780, amt: 2000 },
  { name: 'May', sales: 1890, amt: 2181 },
  { name: 'Jun', sales: 2390, amt: 2500 },
  { name: 'Jul', sales: 3490, amt: 2100 },
];

const ordersData = [
  { name: 'Jan', orders: 2400 },
  { name: 'Feb', orders: 1398 },
  { name: 'Mar', orders: 9800 },
  { name: 'Apr', orders: 3908 },
  { name: 'May', orders: 4800 },
  { name: 'Jun', orders: 3800 },
  { name: 'Jul', orders: 4300 },
];

const inventoryData = [
  { name: 'Product A', quantity: 400 },
  { name: 'Product B', quantity: 300 },
  { name: 'Product C', quantity: 300 },
  { name: 'Product D', quantity: 200 },
];

const Reports = () => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (modalType) => () => {
    setOpenModal(modalType);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <div>
      <Header />
      <Container maxWidth="lg" style={{ marginTop: '64px', marginBottom: '64px' }}>
        <Typography variant="h4" gutterBottom>
          Reports
        </Typography>
        <Typography variant="body1" gutterBottom>
          This is the Reports page. Here you can view various reports and analytics.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" gutterBottom>
                    Sales Report
                  </Typography>
                  <IconButton onClick={handleOpenModal('sales')}>
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
                <ResponsiveContainer width="100%" height={200}>
                  <LineChart data={salesData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" gutterBottom>
                    Orders Report
                  </Typography>
                  <IconButton onClick={handleOpenModal('orders')}>
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart data={ordersData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="orders" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" gutterBottom>
                    Inventory Movement Report
                  </Typography>
                  <IconButton onClick={handleOpenModal('inventory')}>
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
                <ResponsiveContainer width="100%" height={200}>
                  <PieChart>
                    <Pie data={inventoryData} dataKey="quantity" nameKey="name" cx="50%" cy="50%" outerRadius={60} fill="#8884d8">
                      {inventoryData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28', '#FF8042'][index % 4]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Dialog open={openModal === 'sales'} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Sales Report</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Detailed Sales Data</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={salesData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
          <Box mt={3}>
            <Typography variant="h6">Sales Table</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Sales</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salesData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.sales}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModal === 'orders'} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Orders Report</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Detailed Orders Data</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={ordersData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="orders" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
          <Box mt={3}>
            <Typography variant="h6">Orders Table</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Orders</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.orders}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openModal === 'inventory'} onClose={handleCloseModal} maxWidth="md" fullWidth>
        <DialogTitle>Inventory Movement Report</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Detailed Inventory Data</Typography>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie data={inventoryData} dataKey="quantity" nameKey="name" cx="50%" cy="50%" outerRadius={80} fill="#8884d8" label>
                {inventoryData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={['#0088FE', '#00C49F', '#FFBB28', '#FF8042'][index % 4]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <Box mt={3}>
            <Typography variant="h6">Inventory Table</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventoryData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Reports;
