import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'; // Import react-scroll

const Root = styled('div')({
  flexGrow: 1,
});

const Title = styled(Typography)({
  flexGrow: 1,
});

const Header = () => (
  <Root>
    <AppBar position="static" color="primary">
      <Toolbar>
        <Title variant="h6" component={RouterLink} to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          StackrHub
        </Title>
        <Button color="inherit" component={RouterLink} to="/">Home</Button>
        <ScrollLink to="features" smooth={true} duration={500} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color="inherit">Features</Button>
        </ScrollLink>
        <ScrollLink to="pricing" smooth={true} duration={500} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color="inherit">Pricing</Button>
        </ScrollLink>
        <ScrollLink to="contact" smooth={true} duration={500} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Button color="inherit">Contact</Button>
        </ScrollLink>
        <Button color="inherit" component={RouterLink} to="/login">Login</Button>
        <Button color="inherit" component={RouterLink} to="/register">Register</Button>
      </Toolbar>
    </AppBar>
  </Root>
);

export default Header;
