import React, { useState } from 'react';
import { Fade, Backdrop, Modal, Box, Typography, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import { createProduct } from '../../../api';
import { toast } from 'react-toastify';

const CreateProductModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    sku: '',
    name: '',
    price: '',
    qty: '',
    description: '',
    isVariationParent: false,
    isBundle: false,
    companyId: 0,
    imageUrls: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productData = {
    sku: formData.sku,
    name: formData.name,
    price: parseFloat(formData.price),
    qty: parseInt(formData.qty, 10),
    description: formData.description,
    isVariationParent: formData.isVariationParent,
    isBundle: formData.isBundle,
    imageUrls: [],
    };

    try {
      const createdProduct = await createProduct(productData);
      toast.success('Product created successfully');
      handleClose();
    } catch (error) {
      console.error('Error from backend:', error);
      toast.error(error.error || 'There was an error creating the product');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2">
            Create Product
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="SKU"
              name="sku"
              value={formData.sku}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Price"
              name="price"
              value={formData.price}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quantity"
              name="qty"
              value={formData.qty}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isVariationParent}
                  onChange={handleChange}
                  name="isVariationParent"
                />
              }
              label="Is Variation Parent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isBundle}
                  onChange={handleChange}
                  name="isBundle"
                />
              }
              label="Is Bundle"
            />
            <Button type="submit" variant="contained" color="primary">
              Create Product
            </Button>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default CreateProductModal;
