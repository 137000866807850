import React from 'react';
import { Container, Typography, Grid } from '@mui/material';
import ServiceCard from './ServiceCard';

const services = [
  { title: 'Inventory Management', description: 'Manage your inventory across multiple platforms.', icon: 'FiPackage' },
  { title: 'Order Processing', description: 'Automate your order processing with ease.', icon: 'FiShoppingCart' },
  { title: 'Real-time Sync', description: 'Keep your data in sync with real-time updates.', icon: 'FiRefreshCw' },
  { title: 'Multiple Integrations', description: 'Integrating with many possible options.', icon: 'FiLink' },
  { title: 'Customized Options', description: 'Get quotes for company-specific functionalities.', icon: 'FiFolderPlus' },
  { title: 'Detailed Reporting', description: 'Get detailed reports on sales and inventory.', icon: 'FiBarChart2' },
];

const ServicesSection = () => (
  <Container maxWidth="md" id="services" component="section" style={{ padding: '80px 0' }}>
    <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
      Our Services
    </Typography>
    <Grid container spacing={4}>
      {services.map((service) => (
        <Grid item key={service.title} xs={12} sm={6}>
          <ServiceCard service={service} />
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default ServicesSection;
