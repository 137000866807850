import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Checkbox, IconButton, Tooltip, Box, Menu, MenuItem, TablePagination,
  Button, Typography, Paper, Link
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getPurchaseOrdersByCompanyId, createPurchaseOrder, deletePurchaseOrder, printPurchaseOrderInvoice } from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatePurchaseOrderModal from './modals/CreatePurchaseOrderModal';
import EditPurchaseOrderModal from './modals/EditPurchaseOrderModal';
import VendorsModal from './modals/VendorsModal';

const PurchaseOrderList = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState(null);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openVendorsModal, setOpenVendorsModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false); // Changed from showEditModal
  const companyId = localStorage.getItem('companyId');

  useEffect(() => {
    const fetchAndSetPurchaseOrders = async () => {
      try {
        const response = await getPurchaseOrdersByCompanyId(page, rowsPerPage);
        setPurchaseOrders(response.content);
        setTotalCount(response.totalElements);
        localStorage.setItem('totalPOCount', response.totalElements);
      } catch (error) {
        setError('Failed to fetch POs. Please try again later.');
      }
    };
    fetchAndSetPurchaseOrders();
  }, [page, rowsPerPage]);

  const handleMenuClick = (event, purchaseOrderId) => {
    setAnchorEl(event.currentTarget);
    setSelectedPurchaseOrderId(purchaseOrderId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    const poToEdit = purchaseOrders.find(po => po.id === selectedPurchaseOrderId);
    setSelectedPurchaseOrder(poToEdit);
    setOpenEditModal(true); // Changed from setShowEditModal
    handleMenuClose();
  };

  const handleDelete = async () => {
    try {
      await deletePurchaseOrder(selectedPurchaseOrderId);
      setPurchaseOrders((prev) => prev.filter(po => po.id !== selectedPurchaseOrderId));
      toast.success('Purchase Order deleted successfully');
    } catch (error) {
      toast.error('Failed to delete Purchase Order');
    }
    handleMenuClose();
  };

  const handlePrintInvoice = async () => {
    try {
      await printPurchaseOrderInvoice(selectedPurchaseOrderId);
      setPurchaseOrders((prev) => prev.filter(po => po.id !== selectedPurchaseOrderId));
      toast.success('Purchase Order invoice printed successfully');
    } catch (error) {
      toast.error('Failed to print Purchase Order invoice');
    }
    handleMenuClose();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = purchaseOrders.map((purchaseOrder) => purchaseOrder.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  const handleOpenVendorsModal = () => {
    setOpenVendorsModal(true);
  };

  const handleCloseVendorsModal = () => {
    setOpenVendorsModal(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false); // Changed from setShowEditModal
    setSelectedPurchaseOrder(null);
  };

  const handleSavePurchaseOrder = async (purchaseOrder) => {
    try {
      await createPurchaseOrder(purchaseOrder);
      const response = await getPurchaseOrdersByCompanyId(page, rowsPerPage);
      setPurchaseOrders(response.content);
      setTotalCount(response.totalElements);
      handleCloseCreateModal();
      toast.success('Purchase Order created successfully');
    } catch (error) {
      toast.error('Failed to create Purchase Order');
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'PAID':
      case 'SHIPPED':
      case 'RECEIVED':
        return { color: 'green', fontWeight: 'bold' };
      case 'UNPAID':
      case 'UNSHIPPED':
      case 'UNRECEIVED':
        return { color: 'red', fontWeight: 'bold' };
      case 'PARTIAL':
        return { color: 'yellow', fontWeight: 'bold' };
      default:
        return {};
    }
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Purchase Orders
        </Typography>
        <Link
          component="button"
          variant="body1"
          color="primary"
          style={{ textDecoration: 'underline' }}
          onClick={handleOpenVendorsModal}
        >
          Manage Vendors
        </Link>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button variant="contained" color="primary" onClick={handleOpenCreateModal}>
          Create New Purchase Order
        </Button>
      </Box>
      <TableContainer component={Paper} style={{ marginTop: '16px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < purchaseOrders.length}
                  checked={purchaseOrders.length > 0 && selected.length === purchaseOrders.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell><b>ID</b></TableCell>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Created Date</b></TableCell>
              <TableCell><b>Vendor Name</b></TableCell>
              <TableCell><b>Quantity</b></TableCell>
              <TableCell><b>Cost</b></TableCell>
              <TableCell><b>Ship Status</b></TableCell>
              <TableCell><b>Paid Status</b></TableCell>
              <TableCell><b>Receiving Status</b></TableCell>
              <TableCell><b>Actions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchaseOrders.map((purchaseOrder) => {
              const isItemSelected = isSelected(purchaseOrder.id);
              return (
                <TableRow
                  key={purchaseOrder.id}
                  selected={isItemSelected}
                  onClick={() => setSelected((prevSelected) => {
                    if (isItemSelected) {
                      return prevSelected.filter(id => id !== purchaseOrder.id);
                    } else {
                      return [...prevSelected, purchaseOrder.id];
                    }
                  })}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{purchaseOrder.id}</TableCell>
                  <TableCell>{purchaseOrder.name}</TableCell>
                  <TableCell>{formatDateTime(purchaseOrder.createdDate)}</TableCell>
                  <TableCell>{purchaseOrder.vendorName || 'N/A'}</TableCell>
                  <TableCell>{purchaseOrder.quantityOrdered}</TableCell>
                  <TableCell>{purchaseOrder.totalCost.toFixed(2)}</TableCell>
                  <TableCell style={getStatusStyle(purchaseOrder.shippingStatus)}>{purchaseOrder.shippingStatus}</TableCell>
                  <TableCell style={getStatusStyle(purchaseOrder.paymentStatus)}>{purchaseOrder.paymentStatus}</TableCell>
                  <TableCell style={getStatusStyle(purchaseOrder.receivingStatus)}>{purchaseOrder.receivingStatus}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, purchaseOrder.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedPurchaseOrderId === purchaseOrder.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handlePrintInvoice}>Print Invoice</MenuItem>
                      <MenuItem onClick={handleEdit}>Edit</MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
      <ToastContainer />
      <CreatePurchaseOrderModal
        open={openCreateModal}
        onClose={handleCloseCreateModal}
        onSave={handleSavePurchaseOrder}
      />
      <EditPurchaseOrderModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        purchaseOrderId={selectedPurchaseOrderId}
        onSave={() => {
          setPurchaseOrders((prev) => prev.map(po => (po.id === selectedPurchaseOrder.id ? selectedPurchaseOrder : po)));
          handleCloseEditModal();
        }}
      />
      <VendorsModal
        open={openVendorsModal}
        onClose={handleCloseVendorsModal}
        companyId={companyId}
      />
    </Box>
  );
};

export default PurchaseOrderList;
