import React, { useState, useEffect } from 'react';
import {
  Grid, Card, CardContent, Typography, Button, Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, CircularProgress
} from '@mui/material';
import PackageIcon from '@mui/icons-material/Inventory2';
import AddIcon from '@mui/icons-material/Add';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import OrderIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DidYouKnowModal from './modals/DidYouKnowModal';
import CreateOrderModal from './modals/CreateOrderModal';
import CreateProductModal from './modals/CreateProductModal';
import { getQueuedTasks } from '../../api';

const Cards = () => {
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDidYouKnowModal, setOpenDidYouKnowModal] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false); // Added loading state
  const [queuedTasks, setQueuedTasks] = useState([]); // Added tasks state

  const handleOpenProductModal = () => setOpenProductModal(true);
  const handleCloseProductModal = () => setOpenProductModal(false);

  const handleOpenOrderModal = () => setOpenOrderModal(true);
  const handleCloseOrderModal = () => setOpenOrderModal(false);

  const handleExpandClick = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const handleOpenDidYouKnowModal = () => setOpenDidYouKnowModal(true);
  const handleCloseDidYouKnowModal = () => setOpenDidYouKnowModal(false);

  // Fetch queued tasks from the API on component mount
  useEffect(() => {
    const fetchQueuedTasks = async () => {
      try {
        setLoadingTasks(true); // Start loading
        const tasks = await getQueuedTasks();
        setQueuedTasks(tasks || []); // If no tasks are returned, set an empty array
      } catch (error) {
//        toast.error('Failed to fetch queued tasks');
      } finally {
        setLoadingTasks(false); // Stop loading
      }
    };

    fetchQueuedTasks();
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <>
      <Grid container spacing={3} style={{ padding: '16px' }}>
        {/* Create Product */}
        <Grid item xs={3}>
          <Card sx={{ bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <PackageIcon color="primary" sx={{ fontSize: 32 }} />
                <Typography variant="h6" component="div">
                  Create Product
                </Typography>
                <Button variant="contained" color="primary" size="small" style={{ marginTop: '8px' }} onClick={handleOpenProductModal}>
                  Create Product
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Create Order */}
        <Grid item xs={3}>
          <Card sx={{ bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <OrderIcon color="primary" sx={{ fontSize: 32 }} />
                <Typography variant="h6" component="div">
                  Create Order
                </Typography>
                <Button variant="contained" color="primary" size="small" style={{ marginTop: '8px' }} onClick={handleOpenOrderModal}>
                  Create Order
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Task Card with Expand */}
        <Grid item xs={3}>
          <Card sx={{ bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
            <CardContent>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" component="div">
                  Queued Tasks
                </Typography>
                <IconButton onClick={handleExpandClick}>
                  <ExpandMoreIcon />
                </IconButton>
              </Box>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Task Name</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingTasks ? (
                      <TableRow>
                        <TableCell colSpan={3} align="center">
                          <CircularProgress size={24} />
                        </TableCell>
                      </TableRow>
                    ) : (
                      queuedTasks.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={3} align="center">
                            No tasks queued
                          </TableCell>
                        </TableRow>
                      ) : (
                        queuedTasks.map((task) => (
                          <TableRow key={task.id}>
                            <TableCell>{task.id}</TableCell>
                            <TableCell>{task.taskName}</TableCell>
                            <TableCell>{task.currentStatus}</TableCell>
                          </TableRow>
                        ))
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Did You Know */}
        <Grid item xs={3}>
          <Card sx={{ bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <LightbulbIcon color="primary" sx={{ fontSize: 32 }} />
                <Typography variant="h6" component="div">
                  Did You Know?
                </Typography>
                <Button variant="contained" color="primary" size="small" style={{ marginTop: '8px' }} onClick={handleOpenDidYouKnowModal}>
                  Know More
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Task Modal */}
      <Modal open={openModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>Queued Tasks</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Company ID</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Started At</TableCell>
                  <TableCell>Completed At</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Estimated Completion</TableCell>
                  <TableCell>Result</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {queuedTasks.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No tasks queued
                    </TableCell>
                  </TableRow>
                ) : (
                  queuedTasks.map((task) => (
                    <TableRow key={task.id}>
                      <TableCell>{task.id}</TableCell>
                      <TableCell>{task.taskName}</TableCell>
                      <TableCell>{task.companyId}</TableCell>
                      <TableCell>{task.creationDateTime}</TableCell>
                      <TableCell>{task.startedDateTime}</TableCell>
                      <TableCell>{task.completedDateTime}</TableCell>
                      <TableCell>{task.currentStatus}</TableCell>
                      <TableCell>{task.estimatedCompletion}</TableCell>
                      <TableCell>{task.result}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>

      {/* Create Product Modal */}
      <CreateProductModal
        open={openProductModal}
        handleClose={handleCloseProductModal}
      />

      {/* Did You Know Modal */}
      <DidYouKnowModal
        open={openDidYouKnowModal}
        handleClose={handleCloseDidYouKnowModal}
      />

      {/* Create Order Modal */}
      <CreateOrderModal
        open={openOrderModal}
        handleClose={handleCloseOrderModal}
      />
    </>
  );
};

export default Cards;
