import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, IconButton, Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const CompanyInfoCard = ({ companyInfo }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [companyData, setCompanyData] = useState(companyInfo);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyData({ ...companyData, [name]: value });
  };

  const handleSave = () => {
    // Here you can add the logic to save the updated company data
    setIsEditing(false);
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Company Information</Typography>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Box>
        {isEditing ? (
          <>
            <TextField
              label="Company Name"
              name="name"
              value={companyData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Address"
              name="address"
              value={companyData.address}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Phone"
              name="phone"
              value={companyData.phone}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1">Name: {companyData.name}</Typography>
            <Typography variant="body1">Address: {companyData.address}</Typography>
            <Typography variant="body1">Phone: {companyData.phone}</Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyInfoCard;
