import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, TextField, Select, MenuItem, Typography } from '@mui/material';

const SettingsTable = ({ settings }) => {
  const [settingsState, setSettingsState] = useState([]);

  useEffect(() => {
    if (settings) {
      setSettingsState(Object.entries(JSON.parse(settings.settings)).map(([key, setting]) => ({
        id: key,
        ...setting
      })));
    }
  }, [settings]);

  const handleBooleanChange = (id) => (event) => {
    const newSettings = settingsState.map(setting => {
      if (setting.id === id) {
        return { ...setting, value: event.target.checked };
      }
      return setting;
    });
    setSettingsState(newSettings);
  };

  const handleStringChange = (id) => (event) => {
    const newSettings = settingsState.map(setting => {
      if (setting.id === id) {
        return { ...setting, value: event.target.value };
      }
      return setting;
    });
    setSettingsState(newSettings);
  };

  const handleDropdownChange = (id) => (event) => {
    const newSettings = settingsState.map(setting => {
      if (setting.id === id) {
        return { ...setting, value: { ...setting.value, selected: event.target.value } };
      }
      return setting;
    });
    setSettingsState(newSettings);
  };

  const renderValueControl = (setting) => {
    switch (setting.type) {
      case 'Boolean':
        return (
          <Switch
            checked={setting.value}
            onChange={handleBooleanChange(setting.id)}
            color="primary"
          />
        );
      case 'String':
        return (
          <TextField
            value={setting.value}
            onChange={handleStringChange(setting.id)}
            fullWidth
          />
        );
      case 'Dropdown':
        return (
          <Select
            value={setting.value.selected}
            onChange={handleDropdownChange(setting.id)}
            fullWidth
          >
            {setting.value.options.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">Setting Title</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">Description</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">Type</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" fontWeight="bold">Value</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {settingsState.map(setting => (
            <TableRow key={setting.id}>
              <TableCell>{setting.id}</TableCell>
              <TableCell>{setting.rights === 'admin' ? 'Admin' : 'User'}</TableCell>
              <TableCell>{setting.type}</TableCell>
              <TableCell>{renderValueControl(setting)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettingsTable;
