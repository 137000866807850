import React, { useState, useEffect } from 'react';
import { fetchMarketIntegrations, createOrder } from '../../../api';
import { toast } from 'react-toastify';
import { Modal, Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const CreateOrderModal = ({ open, handleClose }) => {

  const formatOrderDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    customerName: '',
    market: 'LOCAL',
    marketIntegrationId: '',
    orderNumber: '',
    marketOrderNumber: '',
    shippingStatus: 'UNSHIPPED',
    paymentStatus: 'UNPAID',
    orderStatus: 'OPEN',
    orderDate: formatOrderDate(new Date()),
    companyId: 0,
    address: null,
  });
  const [marketIntegrations, setMarketIntegrations] = useState([]);
  const [filteredMarketIntegrations, setFilteredMarketIntegrations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const integrations = await fetchMarketIntegrations();
        setMarketIntegrations(integrations);
      } catch (error) {
        toast.error('Failed to fetch market integrations');
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === 'market') {
      if (value === 'LOCAL') {
        setFilteredMarketIntegrations([]);
        setFormData((prevState) => ({
          ...prevState,
          marketIntegrationId: '',
        }));
      } else {
        const filtered = marketIntegrations.filter((integration) => integration.market === value);
        setFilteredMarketIntegrations(filtered);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const companyId = localStorage.getItem('companyId');
    const orderData = { ...formData, companyId: Number(companyId) };

    try {
      await createOrder(orderData);
      toast.success('Order created successfully');
      handleClose();
    } catch (error) {
      console.error('Error from backend:', error);
      toast.error(error.response?.data?.message || 'There was an error creating the order');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="create-order-modal-title"
      aria-describedby="create-order-modal-description"
    >
      <Box sx={{ ...style, width: 400 }}>
        <Typography id="create-order-modal-title" variant="h6" component="h2">
          Create Order
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Customer Name"
            name="customerName"
            value={formData.customerName}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="market-select-label">Market</InputLabel>
            <Select
              labelId="market-select-label"
              id="market-select"
              name="market"
              value={formData.market}
              onChange={handleChange}
              label="Market"
            >
              <MenuItem value="LOCAL">Local</MenuItem>
              <MenuItem value="EBAY">eBay</MenuItem>
              <MenuItem value="AMAZON">Amazon</MenuItem>
              <MenuItem value="SHOPIFY">Shopify</MenuItem>
              <MenuItem value="ETSY">Etsy</MenuItem>
              {/* Add other markets as needed */}
            </Select>
          </FormControl>
          {formData.market !== 'LOCAL' && (
            <FormControl fullWidth margin="normal" required>
              <InputLabel id="market-integration-select-label">Market Integration</InputLabel>
              <Select
                labelId="market-integration-select-label"
                id="market-integration-select"
                name="marketIntegrationId"
                value={formData.marketIntegrationId}
                onChange={handleChange}
                label="Market Integration"
              >
                {filteredMarketIntegrations.map((integration) => (
                  <MenuItem key={integration.id} value={integration.id}>
                    {integration.nickname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Order Number"
            name="orderNumber"
            value={formData.orderNumber}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Market Order Number"
            name="marketOrderNumber"
            value={formData.marketOrderNumber}
            onChange={handleChange}
          />
          <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
            Create Order
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default CreateOrderModal;
