import React, { useState } from 'react';
import { Box, TextField, FormControlLabel, Checkbox, Button, Typography, Grid, RadioGroup, Radio } from '@mui/material';

const PurchaseOrderFilters = ({ onApplyFilters }) => {
  const [filters, setFilters] = useState({
    purchaseOrderId: '',
    sort: {
      createdDate: null,
      shippedDate: null,
      paidDate: null
    },
    searchIntegration: '',
    isActive: false,
    isHidden: false,
    priceMin: '',
    priceMax: '',
    quantityMin: '',
    quantityMax: '',
    createdDateStart: '',
    createdDateEnd: ''
  });

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSortChange = (event, sortField) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      sort: {
        ...prevFilters.sort,
        [sortField]: value
      }
    }));
  };

  const handleSortCheckboxChange = (event, sortField) => {
    const { checked } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      sort: {
        ...prevFilters.sort,
        [sortField]: checked ? 'asc' : null
      }
    }));
  };

  const handleApply = () => {
    const sortField = Object.keys(filters.sort).find(key => filters.sort[key] !== null);
    const sortOrder = sortField ? filters.sort[sortField] : null;

    onApplyFilters({
      ...filters,
      page: 0,
      size: 10,
      sortField,
      sortOrder
    });
  };

  return (
    <Box sx={{ width: '100%', padding: '16px', bgcolor: '#ffffff', borderRadius: 1, boxShadow: 1 }}>
      <Typography variant="subtitle1" gutterBottom>Purchase Order</Typography>
      <TextField
        label="Search Purchase Order By ID"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        name="purchaseOrderId"
        value={filters.purchaseOrderId}
        onChange={handleInputChange}
      />
      <Typography variant="subtitle2" gutterBottom>Sort By</Typography>
      <Box sx={{ pl: 1 }}>
        <FormControlLabel
          control={<Checkbox size="small" checked={filters.sort.createdDate !== null} onChange={(event) => handleSortCheckboxChange(event, 'createdDate')} />}
          label={<Typography variant="body2">Created Date</Typography>}
        />
        <RadioGroup row value={filters.sort.createdDate || ''} onChange={(event) => handleSortChange(event, 'createdDate')}>
          <FormControlLabel value="asc" control={<Radio size="small" />} label={<Typography variant="body2">ASC</Typography>} />
          <FormControlLabel value="desc" control={<Radio size="small" />} label={<Typography variant="body2">DESC</Typography>} />
        </RadioGroup>
        <FormControlLabel
          control={<Checkbox size="small" checked={filters.sort.shippedDate !== null} onChange={(event) => handleSortCheckboxChange(event, 'shippedDate')} />}
          label={<Typography variant="body2">Shipped Date</Typography>}
        />
        <RadioGroup row value={filters.sort.shippedDate || ''} onChange={(event) => handleSortChange(event, 'shippedDate')}>
          <FormControlLabel value="asc" control={<Radio size="small" />} label={<Typography variant="body2">ASC</Typography>} />
          <FormControlLabel value="desc" control={<Radio size="small" />} label={<Typography variant="body2">DESC</Typography>} />
        </RadioGroup>
        <FormControlLabel
          control={<Checkbox size="small" checked={filters.sort.paidDate !== null} onChange={(event) => handleSortCheckboxChange(event, 'paidDate')} />}
          label={<Typography variant="body2">Paid Date</Typography>}
        />
        <RadioGroup row value={filters.sort.paidDate || ''} onChange={(event) => handleSortChange(event, 'paidDate')}>
          <FormControlLabel value="asc" control={<Radio size="small" />} label={<Typography variant="body2">ASC</Typography>} />
          <FormControlLabel value="desc" control={<Radio size="small" />} label={<Typography variant="body2">DESC</Typography>} />
        </RadioGroup>
      </Box>

      <Typography variant="subtitle2" gutterBottom>Filter</Typography>
      <TextField
        label="Integration ID or NickName"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        name="searchIntegration"
        value={filters.searchIntegration}
        onChange={handleInputChange}
      />
      <FormControlLabel
        control={<Checkbox size="small" checked={filters.isActive} onChange={handleInputChange} name="isActive" />}
        label={<Typography variant="body2">Is Active</Typography>}
      />
      <FormControlLabel
        control={<Checkbox size="small" checked={filters.isHidden} onChange={handleInputChange} name="isHidden" />}
        label={<Typography variant="body2">Is Hidden</Typography>}
      />

      <Typography variant="subtitle2" gutterBottom>Price</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Min"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="priceMin"
            value={filters.priceMin}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Max"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="priceMax"
            value={filters.priceMax}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle2" gutterBottom>Quantity</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Min"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="quantityMin"
            value={filters.quantityMin}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Max"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            name="quantityMax"
            value={filters.quantityMax}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle2" gutterBottom>Created Date</Typography>
      <TextField
        label="Start Date"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        name="createdDateStart"
        value={filters.createdDateStart}
        onChange={handleInputChange}
      />
      <TextField
        label="End Date"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small"
        name="createdDateEnd"
        value={filters.createdDateEnd}
        onChange={handleInputChange}
      />

      <Button variant="contained" color="primary" fullWidth size="small" onClick={handleApply}>
        Apply
      </Button>
    </Box>
  );
};

export default PurchaseOrderFilters;
