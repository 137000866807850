import React, { useEffect, useState } from 'react';
import Header from '../shared/LoggedInHeader';
import Footer from '../shared/Footer';
import { Container, Typography, Box, Grid } from '@mui/material';
import UserInfoCard from './cards/UserInfoCard';
import CompanyInfoCard from './cards/CompanyInfoCard';
import SubscriptionInfoCard from './cards/SubscriptionInfoCard';
import SettingsTable from './SettingsTable';
import { getUserAccountInfo } from '../../api';

const Account = () => {
  const [userAccountInfo, setUserAccountInfo] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserAccountInfo = async () => {
      try {
        const userId = localStorage.getItem('userId'); // Assuming userId is stored in local storage
        const data = await getUserAccountInfo(userId);
        setUserAccountInfo(data);
      } catch (error) {
        setError('Failed to fetch account information. Please try again later.');
      }
    };

    fetchUserAccountInfo();
  }, []);

  if (error) {
    return (
      <Container maxWidth="lg" style={{ marginTop: '64px', marginBottom: '64px' }}>
        <Typography variant="h4" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!userAccountInfo) {
    return (
      <Container maxWidth="lg" style={{ marginTop: '64px', marginBottom: '64px' }}>
        <Typography variant="h4">Loading...</Typography>
      </Container>
    );
  }

  return (
    <div>
      <Header />
      <Container maxWidth="lg" style={{ marginTop: '64px', marginBottom: '64px' }}>
        <Typography variant="h4" gutterBottom>
          My Account
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <UserInfoCard userInfo={userAccountInfo.userInfo} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CompanyInfoCard companyInfo={userAccountInfo.companyInfo} />
          </Grid>
          <Grid item xs={12} md={4}>
            <SubscriptionInfoCard />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="h5" gutterBottom>
            Settings
          </Typography>
          <SettingsTable settings={userAccountInfo.settings} />
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default Account;
