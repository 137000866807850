import React, { useState, useEffect } from 'react';
import { updateProduct, getCompanyWarehouses, uploadProductImages } from '../../../api';
import {
  Modal, Tabs, Tab, Box, TextField, Button, Typography, Select, MenuItem, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, Fade, Checkbox, Divider, List, ListItemButton, ListItemText, ListItem
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useDropzone } from 'react-dropzone';

const EditProductModal = ({ open, handleClose, productData }) => {
  const [formData, setFormData] = useState({
    sku: '',
    name: '',
    price: '',
    sellableQuantity: '',
    reservedQuantity: '',
    description: '',
    warehouses: [],
    images: [],
    productMarkets: [],
    companyId: 0,
    isVariationParent: false,
    isBundle: false
  });

  const [availableWarehouses, setAvailableWarehouses] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedMarket, setSelectedMarket] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newImages = acceptedFiles.map((file) => ({
        id: Math.random().toString(36).substr(2, 9),
        name: file.name,
        file: file,
        url: URL.createObjectURL(file),
        sortIndex: formData.images.length + 1, // Assigning the sort index
      }));
      setFormData((prevState) => ({
        ...prevState,
        images: [...prevState.images, ...newImages]
      }));
    }
  });

  useEffect(() => {
    if (open) {
      getCompanyWarehouses()
        .then(setAvailableWarehouses)
        .catch(() => toast.error('Error fetching warehouses'));
    }
  }, [open]);

  useEffect(() => {
    if (productData) {
      setFormData({
        ...productData,
        sellableQuantity: productData.sellableQuantity,
        reservedQuantity: productData.reservedQuantity,
        isBundle: productData.isBundle,
        isVariationParent: productData.isVariationParent,
        warehouses: productData.warehouses || [],
        images: productData.images || [],
      });
    }
  }, [productData]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleWarehouseFieldChange = (index, field, value) => {
    const updatedWarehouses = [...formData.warehouses];
    updatedWarehouses[index] = {
      ...updatedWarehouses[index],
      [field]: value,
    };
    setFormData((prevState) => ({ ...prevState, warehouses: updatedWarehouses }));
  };

  const handleAddWarehouse = () => {
    setFormData((prevState) => ({
      ...prevState,
      warehouses: [
        ...prevState.warehouses,
        { warehouseId: '', qty: 0, shelf: '', bin: '', aisle: '' },
      ],
    }));
  };

  const handleDeleteWarehouse = (index) => {
    const updatedWarehouses = formData.warehouses.filter((_, i) => i !== index);
    setFormData((prevState) => ({ ...prevState, warehouses: updatedWarehouses }));
  };

  const handleImageSubmit = async () => {
    const productId = productData.id;
    const newImages = formData.images.filter((img) => img.file); // Only send new images

    if (newImages.length === 0) {
      toast.warn("No new images to upload.");
      return;
    }

    const formDataToSend = new FormData();

    newImages.forEach((img) => {
      formDataToSend.append('images', img.file);
    });

    try {
      await uploadProductImages(productId, formDataToSend);
      toast.success('Images uploaded successfully');
      handleClose();
    } catch (error) {
      console.error('Error from backend:', error);
      toast.error('There was an error uploading the images');
    }
  };

  const handleDeleteImage = (imageId) => {
    setFormData((prevState) => ({
      ...prevState,
      images: prevState.images.filter((image) => image.id !== imageId)
    }));
  };

  const handleSortIndexChange = (index, newSortIndex) => {
    const updatedImages = [...formData.images];
    updatedImages[index].sortIndex = parseInt(newSortIndex, 10) || 1; // Ensure sort index is numeric
    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedImages = Array.from(formData.images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);

    // Reassigning sortIndex after drag-and-drop
    const updatedImages = reorderedImages.map((img, idx) => ({
      ...img,
      sortIndex: idx + 1 // Update sortIndex based on new position
    }));

    setFormData((prevState) => ({
      ...prevState,
      images: updatedImages
    }));
  };

  const handleMarketClick = (market) => {
    setSelectedMarket(market);
  };

  const handleSaveProduct = async (e) => {
    e.preventDefault();
    try {
      await updateProduct(productData.id, formData);
      toast.success('Product updated successfully');
      handleClose();
    } catch (error) {
      toast.error('Failed to update product');
    }
  };

  const totalAssignedQty = formData.warehouses.reduce((sum, warehouse) => sum + Number(warehouse.qty), 0);
  const availableQty = formData.sellableQuantity - totalAssignedQty;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-product-modal-title"
      aria-describedby="edit-product-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '1200px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '90vh',
          }}
        >
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="General" />
            <Tab label="Images" />
            <Tab label="Markets" />
            <Tab label="Orders" />
            <Tab label="Purchasing" />
            <Tab label="Warehousing" />
            {formData.isBundle && <Tab label="Bundles" />}
            {formData.isVariationParent && <Tab label="Variations" />}
          </Tabs>

          {/* General Tab */}
          {activeTab === 0 && (
            <form onSubmit={handleSaveProduct}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="SKU"
                name="sku"
                value={formData.sku}
                onChange={(e) => setFormData({ ...formData, sku: e.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Price"
                name="price"
                type="number"
                value={formData.price}
                onChange={(e) => setFormData({ ...formData, price: e.target.value })}
              />
              <Box display="flex" gap={2}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Sellable Quantity"
                  name="sellableQuantity"
                  type="number"
                  value={formData.sellableQuantity}
                  onChange={(e) => setFormData({ ...formData, sellableQuantity: e.target.value })}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Reserved Quantity"
                  name="reservedQuantity"
                  type="number"
                  value={formData.reservedQuantity}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Description"
                name="description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              />
              <Box display="flex" alignItems="center" marginTop="8px">
                <Checkbox
                  checked={formData.isVariationParent}
                  onChange={(e) => setFormData({ ...formData, isVariationParent: e.target.checked })}
                  name="isVariationParent"
                />
                <Typography>Is Variation Parent</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={formData.isBundle}
                  onChange={(e) => setFormData({ ...formData, isBundle: e.target.checked })}
                  name="isBundle"
                />
                <Typography>Is Bundle</Typography>
              </Box>
              <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
                Update Product
              </Button>
            </form>
          )}

          {/* Images Tab */}
          {activeTab === 1 && (
            <Box>
              <div {...getRootProps()} style={{ border: '1px dashed grey', padding: '16px', marginTop: '8px' }}>
                <input {...getInputProps()} />
                <Typography>Drag 'n' drop images here, or click to select files</Typography>
              </div>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="images">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {formData.images.map((image, index) => (
                        <Draggable key={image.id} draggableId={image.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '10px',
                                ...provided.draggableProps.style
                              }}
                            >
                              <img
                                src={image.url || `data:image/png;base64,${image.imageData}`}
                                alt={image.name || 'Product Image'}
                                style={{ width: '50px', height: '50px', marginRight: '10px' }}
                              />
                              <Typography>{image.name || 'Image'}</Typography>
                              <TextField
                                label="Index"
                                type="number"
                                value={image.sortIndex}
                                style={{ width: '60px', marginLeft: '10px' }}
                                onChange={(e) => handleSortIndexChange(index, e.target.value)}
                              />
                              <Button onClick={() => handleDeleteImage(image.id)}>Delete</Button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Button variant="contained" color="primary" onClick={handleImageSubmit} style={{ marginTop: '16px' }}>
                Save Images
              </Button>
            </Box>
          )}

          {/* Markets Tab */}
          {activeTab === 2 && (
            <Box display="flex">
              <List component="nav" aria-label="main mailbox folders">
                {formData.productMarkets.map((market) => (
                  <ListItemButton
                    key={market.id}
                    selected={selectedMarket?.id === market.id}
                    onClick={() => handleMarketClick(market)}
                  >
                    <ListItemText primary={market.nickname} secondary={market.marketType} />
                  </ListItemButton>
                ))}
                <ListItem>
                  <Button variant="contained" color="primary">Connect New Integration</Button>
                </ListItem>
              </List>
              <Divider orientation="vertical" flexItem />
              <Box flex={1} padding="16px">
                {selectedMarket ? (
                  <div>
                    <Typography variant="h6">{selectedMarket.nickname}</Typography>
                    <Typography variant="body2">Market ID: {selectedMarket.marketId}</Typography>
                    <Typography variant="body2">Market Type: {selectedMarket.marketType}</Typography>
                  </div>
                ) : (
                  <Typography variant="body1">Select a market to see details</Typography>
                )}
              </Box>
            </Box>
          )}

          {/* Orders Tab */}
          {activeTab === 3 && (
            <div>
              <Typography variant="body1">Orders content</Typography>
            </div>
          )}

          {/* Purchasing Tab */}
          {activeTab === 4 && (
            <div>
              <Typography variant="body1">Purchasing content</Typography>
            </div>
          )}

          {/* Warehousing Tab */}
          {activeTab === 5 && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Manage Warehousing
              </Typography>

              <Typography variant="body2" color="textSecondary" gutterBottom>
                Available quantity: {availableQty}
              </Typography>

              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Warehouse</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Shelf</TableCell>
                      <TableCell>Bin</TableCell>
                      <TableCell>Aisle</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.warehouses.map((warehouse, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Select
                            value={warehouse.warehouseId}
                            onChange={(e) => handleWarehouseFieldChange(index, 'warehouseId', e.target.value)}
                            fullWidth
                          >
                            {availableWarehouses.map((wh) => (
                              <MenuItem key={wh.id} value={wh.id}>
                                {wh.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={warehouse.qty}
                            onChange={(e) => handleWarehouseFieldChange(index, 'qty', e.target.value)}
                            fullWidth
                            error={totalAssignedQty > formData.sellableQuantity}
                            helperText={totalAssignedQty > formData.sellableQuantity && 'Quantity exceeds available stock'}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={warehouse.shelf}
                            onChange={(e) => handleWarehouseFieldChange(index, 'shelf', e.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={warehouse.bin}
                            onChange={(e) => handleWarehouseFieldChange(index, 'bin', e.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            value={warehouse.aisle}
                            onChange={(e) => handleWarehouseFieldChange(index, 'aisle', e.target.value)}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => handleDeleteWarehouse(index)} color="primary">
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button onClick={handleAddWarehouse} variant="contained" color="primary" style={{ marginTop: '16px' }}>
                Add Warehouse Location
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveProduct}
                style={{ marginTop: '16px', float: 'right' }}
                disabled={totalAssignedQty > formData.sellableQuantity}
              >
                Save Product
              </Button>
            </Box>
          )}

          {/* Bundles Tab */}
          {activeTab === 6 && (
            <div>
              <Typography variant="body1">Bundles content</Typography>
            </div>
          )}

          {/* Variations Tab */}
          {activeTab === 7 && (
            <div>
              <Typography variant="body1">Variation content</Typography>
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default EditProductModal;
