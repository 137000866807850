import React, { useState } from 'react';
import { Card, CardContent, Typography, TextField, IconButton, Box, Button, Select, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const SubscriptionInfoCard = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    level: 'Basic',
    nextBillingDate: '2023-12-31',
    previousBilling: '2023-11-30',
    billingStatus: 'Paid'
  });

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubscriptionInfo({ ...subscriptionInfo, [name]: value });
  };

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Subscription Information</Typography>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        </Box>
        {isEditing ? (
          <>
            <TextField
              label="Next Billing Date"
              name="nextBillingDate"
              value={subscriptionInfo.nextBillingDate}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Previous Billing"
              name="previousBilling"
              value={subscriptionInfo.previousBilling}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Billing Status"
              name="billingStatus"
              value={subscriptionInfo.billingStatus}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Select
              label="Subscription Level"
              name="level"
              value={subscriptionInfo.level}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="Basic">Basic</MenuItem>
              <MenuItem value="Pro">Pro</MenuItem>
              <MenuItem value="Enterprise">Enterprise</MenuItem>
            </Select>
            <Button variant="contained" color="primary" onClick={handleEditClick}>
              Save
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1">Subscription Level: {subscriptionInfo.level}</Typography>
            <Typography variant="body1">Next Billing Date: {subscriptionInfo.nextBillingDate}</Typography>
            <Typography variant="body1">Previous Billing: {subscriptionInfo.previousBilling}</Typography>
            <Typography variant="body1">Billing Status: {subscriptionInfo.billingStatus}</Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default SubscriptionInfoCard;
