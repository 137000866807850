import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import { toast } from 'react-toastify';
import {
  fetchTemplatesByCompanyId,
  createNewTemplate,
  uploadTemplateFile,
  updateTemplate,
  deleteTemplate,
} from '../../../api';

const MarketsTemplateModal = ({ open, handleClose, companyId }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateCode, setTemplateCode] = useState('');
  const [previewHtml, setPreviewHtml] = useState('');
  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    if (open) {
      fetchTemplates();
    }
  }, [open]);

  const fetchTemplates = async () => {
    try {
      const response = await fetchTemplatesByCompanyId(companyId);
      setTemplates(response);
      setSelectedTemplate(null);
      setTemplateCode('');
      setPreviewHtml('');
      setTemplateName('');
    } catch (error) {
      toast.error('Failed to fetch templates');
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setTemplateCode(template.template || '');
    setPreviewHtml(template.template || '');
    setTemplateName(template.name || '');
  };

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleTemplateCodeChange = (e) => {
    setTemplateCode(e.target.value);
  };

  const handleRefreshPreview = () => {
    setPreviewHtml(templateCode);
  };

const handleSaveTemplate = async () => {
  if (selectedTemplate) {
    try {
      const templateData = {
        id: selectedTemplate.id,
        name: templateName,
        companyId: selectedTemplate.companyId,
        template: templateCode,
        isDefault: selectedTemplate.isDefault || false,
        createdDate: selectedTemplate.createdDate || new Date().toISOString(),
      };

      await updateTemplate(selectedTemplate.id, templateData);
      toast.success('Template updated successfully');
      fetchTemplates();
    } catch (error) {
      toast.error('Failed to save template');
    }
  }
};

  const handleAddNewTemplate = async () => {
    try {
      const nextTemplateNumber = templates.length + 1;
      const newTemplateName = `sh_template_${nextTemplateNumber}`;
      const newTemplate = {
        name: newTemplateName,
        companyId,
        template: '',
        isDefault: false,
      };
      const savedTemplate = await createNewTemplate(newTemplate);
      toast.success('New template created successfully');
      fetchTemplates();
      setSelectedTemplate(savedTemplate);
      setTemplateCode('');
      setPreviewHtml('');
      setTemplateName(newTemplateName);
    } catch (error) {
      toast.error('Failed to create new template');
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    try {
      await deleteTemplate(templateId);
      toast.success('Template deleted successfully');
      fetchTemplates(); // Refresh the list after deletion
    } catch (error) {
      toast.error('Failed to delete template');
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const uploadedTemplate = await uploadTemplateFile(companyId, file);
        toast.success('Template uploaded successfully');
        fetchTemplates();
        setSelectedTemplate(uploadedTemplate);
        setTemplateCode('');
        setPreviewHtml('');
      } catch (error) {
        toast.error('Failed to upload template');
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>
        Templates
        <IconButton onClick={handleAddNewTemplate} sx={{ float: 'right' }}>
          <AddIcon />
        </IconButton>
        <IconButton component="label" sx={{ float: 'right', marginRight: '8px' }}>
          <input type="file" accept=".html" hidden onChange={handleFileUpload} />
          <DownloadIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', height: '70vh' }}>
        <Box sx={{ width: '30%', marginRight: '16px', borderRight: '1px solid #ccc', paddingRight: '16px' }}>
          {templates.length === 0 ? (
            <Typography>No templates available</Typography>
          ) : (
            templates.map((template) => (
              <Box
                key={template.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px',
                  marginBottom: '8px',
                  cursor: 'pointer',
                  backgroundColor: selectedTemplate?.id === template.id ? '#f0f0f0' : 'transparent',
                }}
                onClick={() => handleTemplateSelect(template)}
              >
                <Box>
                  <Typography variant="body1">{template.name}</Typography>
                  <Typography variant="body2">{new Date(template.createdDate).toLocaleString()}</Typography>
                  <Typography variant="body2">{template.isDefault ? 'Default' : ''}</Typography>
                </Box>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the template from being selected when delete is clicked
                    handleDeleteTemplate(template.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))
          )}
        </Box>
                {/* Right Panel: Template Editor */}
                <Box sx={{ width: '70%' }}>
                  {/* Template Name TextField */}
                  <Box sx={{ marginBottom: '16px' }}>
                    <TextField
                      label="Template Name"
                      fullWidth
                      value={templateName}
                      onChange={handleTemplateNameChange}
                    />
                  </Box>

                  {/* Code Preview */}
          <Box sx={{ height: '50%', marginBottom: '16px', position: 'relative' }}>
            <TextField
              label="Code Preview"
              multiline
              minRows={10}
              maxRows={10}
              fullWidth
              variant="outlined"
              value={templateCode}
              onChange={handleTemplateCodeChange}
            />
            <IconButton onClick={handleRefreshPreview} sx={{ position: 'absolute', top: 0, right: 0 }}>
              <RefreshIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              height: '50%',
              overflow: 'auto',
              padding: '16px',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
            }}
            dangerouslySetInnerHTML={{ __html: previewHtml }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSaveTemplate} color="primary" variant="contained">
          Save Template
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarketsTemplateModal;
