import React, { useState, useEffect } from 'react';
import { Table, Typography, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, IconButton, Box, Menu, MenuItem, TablePagination, Modal, Fade, Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getOrdersByCompanyId, updateOrder, deleteOrder, printOrderInvoice } from '../../api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditOrderModal from './modals/EditOrderModal';
import localIcon from '../../assets/images/local.png';
import amazonIcon from '../../assets/images/amazon.png';
import ebayIcon from '../../assets/images/ebay.png';
import etsyIcon from '../../assets/images/etsy.png';
import shopifyIcon from '../../assets/images/shopify.png';
import magentoIcon from '../../assets/images/magento.png';
import walmartIcon from '../../assets/images/walmart.png';

const getMarketIcon = (market) => {
  switch (market) {
    case 'LOCAL':
      return localIcon;
    case 'AMAZON':
      return amazonIcon;
    case 'EBAY':
      return ebayIcon;
    case 'ETSY':
      return etsyIcon;
    case 'SHOPIFY':
      return shopifyIcon;
    case 'MAGENTO':
      return magentoIcon;
    case 'WALMART':
      return walmartIcon;
    default:
      return null;
  }
};

const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchAndSetOrders = async () => {
      try {
        const response = await getOrdersByCompanyId(page, rowsPerPage);
        setOrders(response.content || []);
        setTotalCount(response.totalElements || 0);
      } catch (error) {
        setError('Failed to fetch orders. Please try again later.');
      }
    };
    fetchAndSetOrders();
  }, [page, rowsPerPage]);

  const handleMenuClick = (event, orderId) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrderId(orderId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePrintInvoice = async () => {
    try {
      await printOrderInvoice(selectedOrderId);
      setSelectedOrder((prev) => prev.filter(po => po.id !== selectedOrderId));
      toast.success('Purchase Order invoice printed successfully');
    } catch (error) {
      toast.error('Failed to print Purchase Order invoice');
    }
    handleMenuClose();
  };

  const handleEdit = () => {
    const orderToEdit = orders.find(order => order.id === selectedOrderId);
    setSelectedOrder(orderToEdit);
    setShowEditModal(true);
    handleMenuClose();
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedOrder(null);
  };

  const handleDelete = async () => {
    try {
      const orderToDelete = orders.find(order => order.id === selectedOrderId);
      if (orderToDelete.paymentStatus === 'PAID' || orderToDelete.shippingStatus === 'SHIPPED') {
        setShowDeleteDialog(true);
      } else {
        await deleteOrder(selectedOrderId);
        toast.success('Order deleted successfully');
        setOrders(orders.filter((order) => order.id !== selectedOrderId));
        handleMenuClose();
      }
    } catch (error) {
      toast.error('Failed to delete order');
      handleMenuClose();
    }
  };

  const confirmDelete = async () => {
    try {
      await deleteOrder(selectedOrderId);
      toast.success('Order deleted successfully');
      setOrders(orders.filter((order) => order.id !== selectedOrderId));
      handleMenuClose();
      setShowDeleteDialog(false);
    } catch (error) {
      toast.error('Failed to delete order');
    }
  };

  const handleRecalculate = async () => {
    try {
      const orderToRecalculate = orders.find(order => order.id === selectedOrderId);
      await updateOrder(orderToRecalculate.id, orderToRecalculate);
      toast.success('Order recalculated successfully');
      handleMenuClose();
    } catch (error) {
      toast.error('Failed to recalculate order');
      handleMenuClose();
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = orders.map((order) => order.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const getStatusColor = (status) => {
    if (status === 'UNPAID' || status === 'UNSHIPPED') {
      return 'red';
    } else if (status === 'PAID' || status === 'SHIPPED') {
      return 'green';
    }
    return 'black';
  };

  if (error) {
    return (
      <Box>
        <Typography variant="h6" color="error" align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < orders.length}
                  checked={orders.length > 0 && selected.length === orders.length}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Market</TableCell>
              <TableCell>Market ID</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Ship Status</TableCell>
              <TableCell>Paid Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              const isItemSelected = isSelected(order.id);
              return (
                <TableRow
                  key={order.id}
                  selected={isItemSelected}
                  onClick={() => setSelected((prevSelected) => {
                    if (isItemSelected) {
                      return prevSelected.filter(id => id !== order.id);
                    } else {
                      return [...prevSelected, order.id];
                    }
                  })}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>
                    <img src={getMarketIcon(order.market)} alt={order.market} width="24" height="24" />
                  </TableCell>
                  <TableCell>{order.marketIntegrationId}</TableCell>
                  <TableCell>{order.orderDate}</TableCell>
                  <TableCell>{order.customerName}</TableCell>
                  <TableCell>{order.totalQty}</TableCell>
                  <TableCell>{order.totalPrice}</TableCell>
                  <TableCell style={{ color: getStatusColor(order.shippingStatus) }}>{order.shippingStatus}</TableCell>
                  <TableCell style={{ color: getStatusColor(order.paymentStatus) }}>{order.paymentStatus}</TableCell>
                  <TableCell>
                    <IconButton onClick={(event) => handleMenuClick(event, order.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && selectedOrderId === order.id}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handlePrintInvoice}>Print Invoice</MenuItem>
                      <MenuItem onClick={handleEdit}>Edit</MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                      <MenuItem onClick={handleRecalculate}>Recalculate</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      <Modal
        open={showEditModal}
        onClose={handleCloseEditModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showEditModal}>
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Edit Order
            </Typography>
            {selectedOrder && <EditOrderModal handleClose={handleCloseEditModal} orderData={selectedOrder} />}
          </Box>
        </Fade>
      </Modal>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This order is either PAID or SHIPPED. Are you sure you want to delete it?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default OrderList;
