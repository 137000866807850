import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FeatureCard from './FeatureCard'; // Create this component to display individual features
import introMobileFeatures from '../assets/images/intro-mobile-features.png';

const features = [
  { title: 'Multi-Marketplace Integrations', description: 'Integrate with multiple online marketplaces like Amazon, eBay, Etsy, Shopify, and more, all from a single dashboard.', icon: 'FiPackage' },
  { title: 'Bundle and Variation Support', description: 'Efficiently manage product bundles and variations, ensuring accurate inventory and streamlined sales processes.', icon: 'FiShoppingCart' },
  { title: 'Rule Customizations', description: 'Set up custom rules for pricing, inventory levels, and order processing to automate and optimize your e-commerce operations.', icon: 'FiRefreshCw' },
  { title: 'AI Listing Template Designing', description: 'Utilize AI-powered tools to create optimized product listings, enhancing visibility and sales across multiple platforms.', icon: 'FiBarChart2' },
  { title: 'Advanced Analytics and Reporting', description: 'Access detailed analytics and reporting tools to gain insights into sales performance, customer behavior, and inventory health.', icon: 'FiLayers' },
  { title: 'Seamless Order Fulfillment', description: 'Integrate with major shipping and fulfillment services to automate and streamline order processing and shipping.', icon: 'FiUser' },
];

const FeaturesSection = () => (
  <Container maxWidth="lg" component="section" style={{ padding: '80px 0' }}>
    <Typography component="h2" variant="h4" align="center" color="textPrimary" gutterBottom>
      Features
    </Typography>
    <Grid container spacing={4} alignItems="center">
      <Grid item xs={12} md={4}>
        <Grid container spacing={4}>
          {features.slice(0, 3).map((feature) => (
            <Grid item key={feature.title} xs={12}>
              <FeatureCard feature={feature} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display="flex" justifyContent="center">
          <img src={introMobileFeatures} alt="Features" style={{ width: '100%', maxWidth: '300px' }} />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={4}>
          {features.slice(3).map((feature) => (
            <Grid item key={feature.title} xs={12}>
              <FeatureCard feature={feature} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default FeaturesSection;
